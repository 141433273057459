<template>
  <div class="companyInfo_warp">
    <div class="head">
      <img :src="data.logo"  alt=""/>
      <div>{{data.companyName}}</div>
    </div>

    <div class="price">
      <div class="price_content">
        <div class="balance flex ai-end" style="display: flex;align-items: flex-end">
          <div class="ta-center">
            <div class="weight-600 text-18" style="font-size: 18px;font-weight: 600">{{minimumFractionDigits(data.balance)}}</div>
            <div class="color-{#666666} text-14" style="color: #666666;font-size: 14px">可提现余额(元)</div>
          </div>
          <div class="color-{#3D7FFF} text-14 ml-10" @click="openWalletDetails"  style="color: #3d7fff;font-size: 14px;margin-left: 10px;cursor: pointer">明细</div>
        </div>
        <div class="deposit flex ai-end" style="display: flex;align-items: flex-end">
          <div class="ta-center " style="text-align: center">
            <div class="weight-600 text-18" style="font-size: 18px;font-weight: 600">{{minimumFractionDigits(data.deposit)}}</div>
            <div class="color-{#666666} text-14" style="color: #666666;font-size: 14px">保证金(元)</div>
          </div>
          <div class="color-{#3D7FFF} text-14 ml-10" @click="openDepositDetails" style="color: #3d7fff;font-size: 14px;margin-left: 10px;cursor: pointer">明细</div>
        </div>
      </div>
<!--      <div class="price_button flex jc-between" style="display: flex;justify-content: space-between">-->
<!--        <ch-button type="cancel" >提现</ch-button>-->
<!--        <ch-button type="ok" >充值</ch-button>-->
<!--      </div>-->
    </div>

    <div class="statistics pt-8" style="margin-top: 8px">
      <div class="weight-600" style="font-weight: 600">基本状况</div>
      <div class="flex ta-center text-14 py-8 color-{#666666}" style="display: flex;text-align: center;font-size: 14px;padding: 8px 0;color: #666666">
        <div class="flex-1" style="flex: 1">
          <div class="color-{#3EA63D} text-20" style="font-size: 20px;color:#3EA63D">{{data.serviceStaffCount && data.serviceStaffCount.onlineNum}}</div>
          <div>上线员工</div>
        </div>
        <div class="flex-1"  style="flex: 1">
          <div class="color-{#F5222D} text-20" style="font-size: 20px;color:#3EA63D">{{data.serviceStaffCount && data.serviceStaffCount.offlineNum}}</div>
          <div>下线员工</div>
        </div>
        <div class="flex-1"  style="flex: 1">
          <div class="color-{#3EA63D} text-20" style="font-size: 20px;color:#3EA63D">{{data.goodsStatistics && data.goodsStatistics.onSaleNum}}</div>
          <div>上架商品</div>
        </div>
        <div class="flex-1"  style="flex: 1">
          <div class="color-{#F5222D} text-20" style="font-size: 20px;color:#3EA63D">{{data.goodsStatistics && data.goodsStatistics.offSaleNum}}</div>
          <div>下架商品</div>
        </div>
      </div>
    </div>

    <div class="score">
      <div class="weight-600" style="font-weight: 600">综合评分</div>
      <div class="flex ta-center jc-between text-14 pt-16" style="display: flex;text-align: center;justify-content: space-between;font-size: 14px;padding-top: 16px;">
        <div class="">
          <div class="text-20" style="font-size: 20px">{{data.score && data.score.fiveStar}}</div>
          <div class="color-{#666666}" style="color: #666666">五星</div>
        </div>
        <div class="">
          <div class="text-20" style="font-size: 20px">{{data.score && data.score.fourStar}}</div>
          <div class="color-{#666666}" style="color: #666666">四星</div>
        </div>
        <div class="">
          <div class="text-20" style="font-size: 20px">{{data.score && data.score.threeStar}}</div>
          <div class="color-{#666666}" style="color: #666666">三星</div>
        </div>
        <div class="">
          <div class="text-20" style="font-size: 20px">{{data.score && data.score.twoStar}}</div>
          <div class="color-{#666666}" style="color: #666666">二星</div>
        </div>
        <div class="">
          <div class="text-20" style="font-size: 20px">{{data.score && data.score.oneStar}}</div>
          <div class="color-{#666666}" style="color: #666666">一星</div>
        </div>
      </div>
    </div>
    <depositDetails ref="depositDetails"  />
    <walletDetail ref="walletDetail"  />
  </div>
</template>

<script>
import depositDetails from "@/views/businesses/infoBusinesses/depositDetails.vue"
import walletDetail from "@/views/businesses/infoBusinesses/walletDetail.vue"
export default {
  components: {
    depositDetails,
    walletDetail
  },
  data(){
    return {
      data:{},
    }
  },
  created() {
    this.getCompanyInfo()
  },

  methods:{
    async getCompanyInfo(){
      this.$curl.get('/hm/homePage/companyInfo').then(res => {
        this.data = res.data
      })
    },
    minimumFractionDigits(number){
      const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0 });
      return formatter.format(number)
    },
    // 保证金明细
    openDepositDetails(){
      this.$refs.depositDetails.openModal()
    },
    // 钱包明细
    openWalletDetails(){
      this.$refs.walletDetail.openModal()
    }
  },
}
</script>



<style scoped lang="scss">
.companyInfo_warp{
  background: #FFFFFF;
  padding: 10px 20px;
}
.head {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E8E8E8;
  img{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.price{
  padding: 10px 0;
  border-bottom: 1px solid #E8E8E8;
  .price_content{
    display: flex;
    justify-content: space-between;
  }
}
</style>